import React from "react";

const CryptoTailored = () => {
  return (
    <section className="crpto_tlrd" id="wallet">
      <div className="conta_iner tlrd_mn">
        <div className="tlrd_lft wow slideInLeft" data-wow-duration="1s">
          <h2>The Crypto Wallet Tailored for You!</h2>
          <p>
            Download our ace crypto wallet & experience excellence in
            sending/receiving crypto.
          </p>
          <div>
            <figure>
              <img src="./static/images/play-store.png" alt="" />
            </figure>
            <figure>
              <img src="./static/images/apple-store.png" alt="" />
            </figure>
          </div>
        </div>
        <div className="tlrd_ryt wow slideInRight" data-wow-duration="2s">
          <figure>
            <img src="./static/images/smartphone.png" alt="" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default CryptoTailored;
