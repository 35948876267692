import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert } from "@mui/material";
import { BorderColor } from "@mui/icons-material";

const StartEarning = () => {
  const [error, setError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [countryName, setCountryName] = useState("");
  const [phoneCode, setPhoneCode] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be atleast 3 characters")
        .required("Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Enter a valid email address"),
      phone: Yup.number()
        // .min(3, "Phone must be greater than or equal to 3")
        .required("Phone is required"),
    }),
  });

  const handleChangePhone = (phone, country) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  return (
    <section className="strt_erng ">
      <div className="conta_iner mn_erng">
        <h2>Start earning today</h2>
        <p>
          Coinbase has a variety of features that make it the best place to
          start trading
        </p>
        <div className="btn_wrp">
          <button className="cmn_btn">SIGN UP NOW</button>
        </div>

        <div className="cnct_frm" id="cntct_frm">
          <div className="cnct_lft">
            <h4>
              Blocktunix:
              <span className="d_blck">Design! Develop! Decentralize!</span>
            </h4>

            <p>
              We Are Blockchain Technocrats, Aiming to Add Innovation
              <span className="d_blck"> in Decentralized Apps</span>
            </p>
            <figure>
              <img src="/static/images/cnct_frm_img.svg" alt="" />
            </figure>
            <ul>
              <li>
                <p>
                  2500+ successful products delivered by{" "}
                  <span className="d_blck">250+ certified experts</span>
                </p>
              </li>
              <li>
                <p>
                  Mellto Dinein Droob Celloux Namshi
                  <span className="d_blck">Vrbo Luxbubble Cure</span>
                </p>
              </li>
            </ul>
          </div>
          <div className="cnct_ryt">
            <h4>Speak with our Blockchain Experts</h4>
            <p>Please fill the form below</p>
            <form onSubmit={formik.handleSubmit} style={{ paddingTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    // sx={{
                    //   border: Boolean(formik.touched.name && formik.errors.name)
                    //     ? "1px solid red"
                    //     : "1px solid white",
                    // }}
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    {...formik.getFieldProps("name")}
                    type="text"
                    placeholder="Full name"
                    variant="outlined"
                    className={
                      Boolean(formik.touched.name && formik.errors.name)
                        ? "err_inpt"
                        : "inpt_fld"
                    }
                    focused={false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <PhoneInput
                    country={"us"}
                    value={phoneCode + formik.values.phone}
                    enableSearch={true}
                    placeholder="Phone Number"
                    className={
                      Boolean(formik.touched.phone && formik.errors.phone)
                        ? "err_inpt"
                        : "inpt_fld"
                    }
                    inputStyle={{ backgroundColor: "transparent" }}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <p className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {" "}
                  <TextField
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    {...formik.getFieldProps("email")}
                    type="text"
                    placeholder="Email"
                    variant="outlined"
                    className={
                      Boolean(formik.touched.email && formik.errors.email)
                        ? "err_inpt"
                        : "inpt_fld"
                    }
                    focused={false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    placeholder="Write your message here"
                    variant="outlined"
                    multiline
                    rows={4}
                    className="msg_fld"
                    focused={false}
                    fullWidth
                  />
                </Grid>
                <div className="sbmt btn_wrp">
                  <button
                    type=" submit"
                    disabled={formik.isSubmitting}
                    className="sbmt_btn cmn_btn"
                  >
                    Submit
                  </button>
                </div>
              </Grid>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StartEarning;
