import React, { useEffect } from "react";
import MainContainer from "../../layouts/MainContainer";

const TermsOfUse = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <MainContainer>
      <div className="conta_iner trms_use">
        <h1>Terms of Use</h1>
        <p>
          This Terms of Use outlines the user policy of Blocktunix, its services
          and liabilities involving the use of our services and products. By
          registering for any of our services, you agree that you have read and
          accepted all the terms and conditions outlined in this document.
        </p>
        <h4>Coverage & scope</h4>
        <p>
          This Terms of Use covers the treatment of use of services and products
          Blocktunix and/or a website owned and operated by Blocktunix. This
          document is not applicable to companies that Blocktuixhas no ownership
          of or does not control, or of persons that are not under the
          employment and management of Blocktunix, including any third-party
          service and/or product providers bound by contract and any third-party
          websites to which Blocktunix website links.
        </p>
        <h4>Filing a complaint</h4>
        <p>
          In case of filing a complaint, Blocktunix will try and come to a
          mutually acceptable solution if a user is unsatisfied with any of our
          services. Revocation occurs when things don't work out. If You think
          about filing a claim, please write to us before launching a request.
        </p>
        <p>
          You should only file complaints if we are unable to affect a mutually
          satisfactory solution.
        </p>
        <h4>Eligibility for refund</h4>
        <p>
          We handle every project in a professional manner to ensure that the
          client is delivered what was mentioned as per the terms and conditions
          of the proposal. However, if the client has gone through the dispute
          resolution process, refund claims will be considered as per the
          following schedule which clearly lists the services and our Terms of
          Use related to each service.
        </p>
        <h4>Dedicated hiring</h4>
        <p>
          Blocktunix offers a full refund if there is a delay in starting the
          project by either the programmer / designer / content writer / SEO
          expert (or any other resource / staff). We will offer a proportionate
          refund if the client cancels the project at any point in time. We
          offer no refund for days worked. If the client is not satisfied with
          the work at any point in time, they must immediately notify our
          Project Manager and ask for discounts for any work not carried out. We
          will not accept any such negotiation at a later date.
        </p>
        <h4>Applicability of the delivery policy</h4>
        <p>
          Timely payments: A project is not considered to be void if the agreed
          upon / listed payments are not clear. Timely communication from the
          client: Blocktunix is not bound by the Terms of Use. The client must
          provide timely information for successful completion of the project.
          Blocktuix will not accept any responsibility for the delay or failure
          of completing the project arising out of miscommunication or lack of
          communication in a timely manner. Completeness of information provided
          by the client: Blocktunix is not obligated to respect its delivery
          and/or refund commitments if the client fails to provide complete
          information/scope of the project to Blocktunix at initializing stage
          of the project. Blocktunix will only compensate for the delay of
          delivery, unless there is a special agreement signed with a penalty
          clause for delay in delivery.
        </p>
      </div>
    </MainContainer>
  );
};

export default TermsOfUse;
