import React from "react";

const CryptoWallet = () => {
  return (
    <section className="crypto_wlt">
      <div className="conta_iner mn_wlt">
        <div className="crypto_lft wow slideInLeft" data-wow-duration="2s">
          <figure>
            <img src="./static/images/wallet.png" alt="" />
          </figure>
        </div>
        <div className="crypto_ryt wow slideInRight" data-wow-duration="2s">
          <h2>Crypto Wallets Are the Most Secure Banks</h2>
          <p>
            A full-stack crypto wallet is nonetheless a jack of all trades, and
            the safety measures it brings along are meticulous.
          </p>
          <h4>Two-Factor Authentication</h4>
          <p>
            Crypto wallets bring you two-factor authentication, that ensures the
            safety of your crypto coins with 100% security.
          </p>
          <h4>Multi-Signature Support</h4>
          <p>
            To diminish the risk of loss or theft of crypto the multi-signature
            support adds layers of security via multiple key holders.
          </p>
          <h4>Cold Storage</h4>
          <p>
            Cyber hacks, unauthorized access, and other vulnerabilities, are
            never a concern as the cold storage ensures complete encryption.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CryptoWallet;
