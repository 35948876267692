import React, { useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Slider from "react-slick";
const BlocktunixRating = () => {
  const slideRef = useRef(null);

  const data = [
    {
      id: 1,
      user_image: "./static/images/user1.png",
      user_name: "Reece Willamson ",
      user_des: "Senior director at Bajigur.us",
      user_reviews:
        " I would like to thank Apptunix for all their hard work. The team had a huge contribution to get everything completed on time. The team communication with the team has been very quick and transparent",
    },
    {
      id: 2,
      user_image: "./static/images/user2.jpeg",
      user_name: "Gilbert",
      user_des: "Senior director at Bajigur.us",
      user_reviews:
        " I would like to thank Apptunix for all their hard work. The team had a huge contribution to get everything completed on time. The team communication with the team has been very quick and transparent",
    },
    {
      id: 3,
      user_image: "./static/images/user3.jpeg",
      user_name: "Roberto",
      user_des: "Senior director at Bajigur.us",
      user_reviews:
        " I would like to thank Apptunix for all their hard work. The team had a huge contribution to get everything completed on time. The team communication with the team has been very quick and transparent",
    },
    {
      id: 4,
      user_image: "./static/images/user4.jpeg",
      user_name: "Robinson",
      user_des: "Senior director at Bajigur.us",
      user_reviews:
        "I would like to thank Apptunix for all their hard work. The team had a huge contribution to get everything completed on time. The team communication with the team has been very quick and transparent",
    },
    {
      id: 5,
      user_image: "./static/images/user5.jpeg",
      user_name: "Harris",
      user_des: "Senior director at Bajigur.us",
      user_reviews:
        " I would like to thank Apptunix for all their hard work. The team had a huge contribution to get everything completed on time. The team communication with the team has been very quick and transparent",
    },
    {
      id: 6,
      user_image: "./static/images/user6.jpeg",
      user_name: "Glen",
      user_des: "Senior director at Bajigur.us",
      user_reviews:
        " I would like to thank Apptunix for all their hard work. The team had a huge contribution to get everything completed on time. The team communication with the team has been very quick and transparent",
    },
    {
      id: 7,
      user_image: "./static/images/user7.jpeg",
      user_name: "Joshua",
      user_des: "Senior director at Bajigur.us",
      user_reviews:
        "I would like to thank Apptunix for all their hard work. The team had a huge contribution to get everything completed on time. The team communication with the team has been very quick and transparent",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    centerPadding: "60px",
    slidesToShow: 2,
    slidesToScroll: 1,
    slidesPerRow: 1,
    speed: 1000,
    arrows: true,
    prevArrow: <KeyboardArrowLeftIcon />,
    nextArrow: <KeyboardArrowRightIcon />,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section className="blctnx_rtng">
      <div className="conta_iner mn_rtng">
        <div className="rtng_ryt">
          <figure>
            <img src="./static/images/quotation_img.svg" alt="" />
          </figure>
          <h2>{data[activeSlide]?.user_name}</h2>
          <p className="user_des">{data[activeSlide]?.user_des}</p>
          <div className="rtng_str">
            <figure>
              <img src="./static/images/star.png" alt="" />
            </figure>
            <figure>
              <img src="./static/images/star.png" alt="" />
            </figure>
            <figure>
              <img src="./static/images/star.png" alt="" />
            </figure>
            <figure>
              <img src="./static/images/star.png" alt="" />
            </figure>
            <figure>
              <img src="./static/images/rating.png" alt="" />
            </figure>
          </div>
          <p>{data[activeSlide]?.user_reviews}</p>
        </div>

        <div className="rtng_lft">
          <Slider {...settings} ref={slideRef}>
            {data.map((item, i) => {
              return (
                <figure key={i}>
                  <img src={item.user_image} alt="" />
                </figure>
              );
            })}
          </Slider>
          <div className="arrow_btns">
            <div
              onClick={() => {
                // if (activeUser < data.length - 1) {
                slideRef.current?.slickPrev();
                // setActiveSlide(activeSlide - 1);
                //   setActiveUser(activeUser + 1);
                // }
              }}
            >
              <NavigateBeforeIcon />
            </div>
            <div
              onClick={() => {
                // if (activeSlide < 0) {
                //   // setActiveSlide(data.length - 3);
                //   // setActiveUser(data.length - 1);
                //   slideRef.current?.slickGoTo(activeSlide - 1);
                //   // slideRef.current?.slickGoTo(data.length - 3);
                // } else {
                slideRef.current?.slickNext();
                // setActiveSlide(activeSlide + 1);
                // setActiveUser(activeUser - 1);
                // }
              }}
            >
              <NavigateNextIcon />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlocktunixRating;
