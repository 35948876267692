import React from "react";
import { CryptoToday } from "../../components";

const PopularCrypto = () => {
  return (
    <section className="mn_crpto" id="exchange">
      <div className="pplr_crpto">
        <div className="conta_iner">
          <h2>Popular cryptocurrency today</h2>
          <p>
            Coinbase has a variety of features that make it the best place to
            start trading
          </p>
        </div>
      </div>
      <div>
        <CryptoToday />
      </div>
    </section>
  );
};

export default PopularCrypto;
