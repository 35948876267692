import React, { useEffect } from "react";
import MainContainer from "../../layouts/MainContainer";
const PrivacyPolicy = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <MainContainer>
      <div className=" conta_iner pri_plcy">
        <h1>Privacy Policy</h1>
        <p>
          When ordering or registering on our site, as appropriate, you may be
          asked to enter your name, email address, phone number or other details
          to help you with your experience.
        </p>
        <h4>When do we collect information?</h4>
        <p>
          We collect information from you when you subscribe to a newsletter,
          fill out a form or enter information on our site.
        </p>
        <h4>How do we use your information?</h4>
        <p>
          We may use the information we collect from you when you register, make
          a purchase, sign up for our newsletter, respond to a survey or
          marketing communication, surf the website, or use certain other site
          features in the following ways:
        </p>
        <ul>
          <li>
            <p>
              To personalize user's experience and to allow us to deliver the
              type of content and product offerings in which you are most
              interested.
            </p>
          </li>
          <li>
            <p>
              To administer a contest, promotion, survey or other site feature.
            </p>
          </li>
          <li>
            <p>
              To send periodic emails regarding your order or other products and
              services.
            </p>
          </li>
          <li>
            <p>How do we protect visitor information?</p>
          </li>
          <li>
            <p>
              We do not use vulnerability scanning and/or scanning to PCI
              standards and we do not use Malware Scanning. We do not store any
              personal identifiable information on our servers.
            </p>
          </li>
        </ul>
        <h4>Do we use 'cookies'?</h4>
        <p>
          Yes. Cookies are small files that a site or its service provider
          transfers to your computer's hard drive through your Web browser (if
          you allow) that enables the site's or service provider's systems to
          recognize your browser and capture and remember certain information.
          For instance, we use cookies to help us remember and process the items
          in your shopping cart. They are also used to help us understand your
          preferences based on previous or current site activity, which enables
          us to provide you with improved services, We also use cookies to help
          us compile aggregate data about site traffic and site interaction so
          that we can offer better site experiences and tools in the future.
        </p>
        <h4>We use cookies to :</h4>
        <p>
          Compile aggregate data about site traffic and site interactions in
          order to offer better site experiences and tools in the future. We may
          also use trusted third party services that track this information on
          our behalf. You can choose to have your computer warn you each time a
          cookie is being sent, or you can choose to turn off all cookies. You
          do this through your browser (like Internet Explorer, Mozilla Firefox
          or Google Chrome) settings. Each browser is a little different, so
          look at your browser's Help menu to learn the correct way to modify
          your cookies. If you disable cookies off, some features will be
          disabled. It won't affect the user's experience that make your site
          experience more efficient and some of our services will not function
          properly. However, you can still submit inquiries.
        </p>
        <h4>Third Party Disclosure</h4>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information.
        </p>
        <h4>Third Party Links</h4>
        <p>
          We do not include or offer third party products or services on our
          website.
        </p>
        <h4>Third Party Trademarks</h4>
        <p>
          Please note that the use of trademarked terms used in content and Meta
          tags of this website is just for advertising purpose and they are the
          trademarks of their respective owners only. This is the way in which
          the web development industry refers to the development of a website or
          similar to some existing websites or apps like Uber, YouTube and
          WhatsApp. We certify that the whole code and design is developed by
          our in-house team of developers and designers and nothing has been or
          will be utilized from other websites. Blocktunix website is by no
          means connected with other brand's website.
        </p>
        <h4>Google</h4>
        <p>
          Google's advertising requirements can be summed up by Google's
          Advertising Principles. They are put in place to provide a positive
          experience for users.{" "}
          <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
            https://support.google.com/adwordspolicy/answer/1316548?hl=en
          </a>
          We along with third-party vendors, such as Google use first-party
          cookies (such as the Google Analytics cookies) and third-party
          cookies, compile data regarding user interactions with ad impressions,
          and other ad services functions as they relate to our website
        </p>
        <h4>Opting-out:</h4>
        <p>
          Users can set preferences for how Google advertises to you using the
          Google Ad Settings page. Alternatively, you can opt out by visiting
          the Network Advertising initiative opt out page or permanently using
          the Google Analytics Opt Out Browser add on.
        </p>
        <h4>Contacting Us</h4>
        <p>
          If there are any questions regarding this privacy policy, you may
          contact us using the information below. C-127 Phase VIII Industrial
          Area Mohali India 160071 info@blocktunixtunix.com
        </p>
      </div>
    </MainContainer>
  );
};
export default PrivacyPolicy;
