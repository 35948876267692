import dayjs from "dayjs";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="ft_r">
      <div className="conta_iner mn_ftr">
        <ul>
          <li className="ftr_sec">
            <figure className="logo">
              <img src="./static/images/blocktunix_logo.svg" alt="" />
            </figure>
            <p>
              Coinbase has a variety of features that <span />
              make it the best place to start trading
            </p>
            <div className="scl">
              <p>Follow Us On:</p>
              <div className="scl_icns">
                <Link to="https://www.facebook.com/Blocktunix" target="_blank">
                  <figure>
                    <img src="./static/images/facebook.png" alt="" />
                  </figure>
                </Link>
                <Link to="https://twitter.com/Blocktunix" target="_blank">
                  <figure>
                    <img src=" ./static/images/twitter.png" alt="" />
                  </figure>
                </Link>
                <Link
                  to="https://www.instagram.com/blocktunix/"
                  target="_blank"
                >
                  <figure>
                    <img src="./static/images/instagram.png" alt="" />
                  </figure>
                </Link>
                <Link
                  to="https://www.linkedin.com/company/blocktunix/"
                  target="_blank"
                >
                  <figure>
                    <img src="./static/images/linkedin.png" alt="" />
                  </figure>
                </Link>
              </div>
            </div>
          </li>
          <li>
            <h4>ABOUT</h4>
            <HashLink smooth to="/#cntct_frm">
              <p>Company </p>
            </HashLink>
            <HashLink smooth to="/#cntct_frm">
              <p>Careers</p>
            </HashLink>
          </li>
          <li>
            <h4>RESOURCES</h4>
            <p>Bitcoin price </p>
            <p>Proof of Reserves</p>
            <p>Discover</p>
            <p>Help Centre</p>
            <p>Status </p>
          </li>
          <li>
            <h4>PRODUCTS</h4>
            <HashLink smooth to="/#features">
              <p> Features </p>
            </HashLink>
            <HashLink smooth to="/#wallet">
              <p>Wallet</p>
            </HashLink>
            <HashLink smooth to="/#exchange">
              <p>Exchange</p>
            </HashLink>
          </li>
          <li>
            <h4>LEGAL</h4>
            <p onClick={() => navigate("/termsofuse")}>Terms of use </p>
            <p>Customer protection</p>
            <p>Legal & compliance</p>

            <p onClick={() => navigate("/privacypolicy")}>Privacy policy</p>
          </li>
        </ul>
      </div>
      <div className="btm_ftr">
        <p>© {dayjs().year()} Blocktunix. All rights reserved.</p>
      </div>
    </footer>
  );
}
