import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Header2 } from "../features";

export default function Header() {
  const navigate = useNavigate();
  return (
    <header className="hd_r">
      <div className="conta_iner hdr_2">
        <Header2 />
        <div>
          <figure onClick={() => navigate("/")}>
            <img src="./static/images/blocktunix_logo.svg" alt="" />
          </figure>
        </div>
      </div>
      <div className="conta_iner mn_hdr">
        <div className="hdr_lft">
          <figure onClick={() => navigate("/")}>
            <img src="./static/images/blocktunix_logo.svg" alt="" />
          </figure>
        </div>
        <div className="hdr_ryt">
          <ul>
            <HashLink smooth to="/#features">
              <li>
                <h6> Features</h6>
              </li>
            </HashLink>
            <HashLink smooth to="/#wallet">
              <li>
                <h6>Wallet</h6>
              </li>
            </HashLink>
            <HashLink smooth to="/#exchange">
              <li>
                <h6>Exchange</h6>
              </li>
            </HashLink>
          </ul>
        </div>
      </div>
    </header>
  );
}
