import { Route, Routes } from "react-router-dom";
import Pages from "./pages";

const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="/privacypolicy" element={<Pages.PrivacyPolicy />} />
        <Route path="/termsofuse" element={<Pages.TermsOfUse />} />
      </Routes>
    </>
  );
};

export default Router;
