import React from "react";

const CryptoBenifits = () => {
  return (
    <section className="crypto_bnfts">
      <div className="conta_iner bnfts">
        <h2>What Are the Benefits of Crypto Wallets?</h2>
        <p>
          Knowing the perks that crypto wallets offer will help you understand
          their prominence in the digital world!
        </p>
        <ul className="mn_bnfts">
          <li className="wow zoomIn" data-wow-duration="2s">
            <figure>
              <img src="./static/images/secure.png" alt="" />
            </figure>
            <h2>
              Secure
              <span className="d_blck"> Transactions</span>
            </h2>
            <p>
              The crypto wallet transactions
              <span className="d_blck"> are backed up by blockchain</span>
              <span className="d_blck">technology that mitigates the</span>
              <span className="d_blck"> risks of fishing & hacks.</span>
            </p>
            <span className="lst_brdr"></span>
          </li>
          <li className="wow zoomIn" data-wow-duration="2s">
            <figure className="cntr_img">
              <img src="./static/images/mob-in-hnd.png" alt="" />
            </figure>
            <h2>
              Enhanced <span className="d_blck"> Privacy</span>
            </h2>
            <p>
              Your identity & data is protected{" "}
              <span className="d_blck">with two-factor authentication</span>
              <span className="d_blck">and you are the sole owner of</span>
              <span className="d_blck">your cryptocurrency exclusively.</span>
            </p>
            <span className="lst_brdr"></span>
          </li>
          <li className="wow zoomIn" data-wow-duration="2s">
            <figure>
              <img src="./static/images/magnifying_glass.png" alt="" />
            </figure>
            <h2>
              Crypto
              <span className="d_blck"> Management</span>
            </h2>
            <p>
              The nature of our wallet is diverse,
              <span className="d_blck"> & hence you are not confined to</span>
              <span className="d_blck"> any particular currency, a wide</span>
              <span className="d_blck"> range of crypto is managed here.</span>
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default CryptoBenifits;
