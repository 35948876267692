import React, { useEffect } from "react";
import WOW from "wowjs";

import {
  Banner,
  BlocktunixRating,
  CryptoBenifits,
  CryptoTailored,
  CryptoWallet,
  PopularCrypto,
  StartEarning,
  WhyBlocktunix,
} from "../../features";

import MainContainer from "../../layouts/MainContainer";
const Home = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <MainContainer>
      <Banner />
      <CryptoWallet />
      <PopularCrypto />
      <CryptoBenifits />
      <CryptoTailored />
      <WhyBlocktunix />
      <BlocktunixRating />
      <StartEarning />
    </MainContainer>
  );
};

export default Home;
