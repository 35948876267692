import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Divider } from "@mui/material";
import { HashLink } from "react-router-hash-link";

export default function Header2() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="mnu">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src="/static/images/hmbrg.svg" alt="" />
      </IconButton>

      <Menu
        className="hdr_mnu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          style: {
            height: "100vh",
            width: "300px",
            padding: "0 8px",
          },
        }}
      >
        <HashLink smooth to="/#features">
          <MenuItem className="mnu_itm">Features</MenuItem>
        </HashLink>
        <Divider className="dvdr" />
        <HashLink smooth to="/#wallet">
          <MenuItem className="mnu_itm">Wallet</MenuItem>
        </HashLink>
        <Divider className="dvdr" />
        <HashLink smooth to="/#exchange">
          <MenuItem className="mnu_itm">Exchange</MenuItem>{" "}
        </HashLink>
      </Menu>
    </div>
  );
}
