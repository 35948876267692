import { Box, Grid } from "@material-ui/core";
import React from "react";

const WhyBlocktunix = () => {
  const data = [
    {
      id: 1,
      image: "./static/images/like.png",
      text: "Best-Industry Expertise",
    },
    {
      id: 2,
      image: "./static/images/custom.png",
      text: "100% Customization",
    },
    {
      id: 3,
      image: "./static/images/idea.png",
      text: "Cost-Effective Solution",
    },
    {
      id: 4,
      image: "./static/images/support.png",
      text: "Round-The-Clock Support",
    },
  ];
  return (
    <section className="why_blcktnx" id="features">
      <div className="conta_iner mn_blctnx">
        <div className="blktnx_wlt">
          <h2>Why Choose Blocktunix Wallet?</h2>
          <p>
            We look forward to every detail relevant to the blockchain tech that
            makes the<span> wallet feasible & adept!</span>
          </p>
        </div>
        <Grid className="blktnx_ftrs" container spacing={4}>
          {data.map((item, id) => {
            return (
              <Grid item xs={12} sm={6} md={3} lg={3} key={id}>
                <Box
                  className={
                    id % 2 !== 0
                      ? "ftrs_crd ftrs_crd_change wow zoomIn"
                      : "ftrs_crd wow zoomIn"
                  }
                  data-wow-duration="2s"
                >
                  <figure>
                    <img src={item.image} />
                  </figure>
                  <p>{item.text}</p>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </section>
  );
};

export default WhyBlocktunix;
