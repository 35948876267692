import React from "react";
import { HashLink } from "react-router-hash-link";

const Banner = () => {
  return (
    <section className="hm_bnr">
      <div className="conta_iner mn_bnr">
        <div className="bnr_lft">
          <h1 className="wow zoomIn" data-wow-duration="2s">
            Blocktunix <span className="wlt">Wallet</span>
            <br /> <span>Your Digital Reserve!</span>
          </h1>
          <p className="wow zoomIn" data-wow-duration="2s">
            Manage your cryptocurrency worldwide with a <br />
            premium, secure & top-notch crypto wallet!
          </p>
          <div className="btn_wrp ">
            <HashLink smooth to="/#wallet">
              <button className="cmn_btn ">Get Started</button>
            </HashLink>
          </div>
        </div>
        <div className="bnr_ryt">
          <figure className=" wow flipInY" data-wow-duration="2s">
            <img src="./static/images/bnr_img2.png" alt="" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default Banner;
