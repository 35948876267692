import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const CryptoToday = () => {
  const [data, setData] = useState([
    {
      id: 1,
      name: "Bitcoin",
      symbol: "BTC",
      slug: "bitcoin",
      num_market_pairs: 9995,
      date_added: "2013-04-28T00:00:00.000Z",
      tags: [
        "mineable",
        "pow",
        "sha-256",
        "store-of-value",
        "state-channel",
        "coinbase-ventures-portfolio",
        "three-arrows-capital-portfolio",
        "polychain-capital-portfolio",
        "binance-labs-portfolio",
        "blockchain-capital-portfolio",
        "boostvc-portfolio",
        "cms-holdings-portfolio",
        "dcg-portfolio",
        "dragonfly-capital-portfolio",
        "electric-capital-portfolio",
        "fabric-ventures-portfolio",
        "framework-ventures-portfolio",
        "galaxy-digital-portfolio",
        "huobi-capital-portfolio",
        "alameda-research-portfolio",
        "a16z-portfolio",
        "1confirmation-portfolio",
        "winklevoss-capital-portfolio",
        "usv-portfolio",
        "placeholder-ventures-portfolio",
        "pantera-capital-portfolio",
        "multicoin-capital-portfolio",
        "paradigm-portfolio",
        "btc-ecosystem",
      ],
      max_supply: 21000000,
      circulating_supply: 19306975,
      total_supply: 19306975,
      platform: null,
      cmc_rank: 1,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 22360.77304394608,
          volume_24h: 26177354921.896736,
          volume_change_24h: 18.8699,
          percent_change_1h: -0.04969927,
          percent_change_24h: -4.22652037,
          percent_change_7d: -6.40037903,
          percent_change_30d: -3.09457012,
          percent_change_60d: 33.63657328,
          percent_change_90d: 31.75381759,
          market_cap: 431718886140.14087,
          market_cap_dominance: 42.0691,
          fully_diluted_market_cap: 469576233922.87,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 1027,
      name: "Ethereum",
      symbol: "ETH",
      slug: "ethereum",
      num_market_pairs: 6517,
      date_added: "2015-08-07T00:00:00.000Z",
      tags: [
        "pos",
        "smart-contracts",
        "ethereum-ecosystem",
        "coinbase-ventures-portfolio",
        "three-arrows-capital-portfolio",
        "polychain-capital-portfolio",
        "binance-labs-portfolio",
        "blockchain-capital-portfolio",
        "boostvc-portfolio",
        "cms-holdings-portfolio",
        "dcg-portfolio",
        "dragonfly-capital-portfolio",
        "electric-capital-portfolio",
        "fabric-ventures-portfolio",
        "framework-ventures-portfolio",
        "hashkey-capital-portfolio",
        "kenetic-capital-portfolio",
        "huobi-capital-portfolio",
        "alameda-research-portfolio",
        "a16z-portfolio",
        "1confirmation-portfolio",
        "winklevoss-capital-portfolio",
        "usv-portfolio",
        "placeholder-ventures-portfolio",
        "pantera-capital-portfolio",
        "multicoin-capital-portfolio",
        "paradigm-portfolio",
        "injective-ecosystem",
      ],
      max_supply: null,
      circulating_supply: 122373866.2178,
      total_supply: 122373866.2178,
      platform: null,
      cmc_rank: 2,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 1566.033081220955,
          volume_24h: 9738139991.007078,
          volume_change_24h: 37.6363,
          percent_change_1h: -0.02130123,
          percent_change_24h: -4.23263089,
          percent_change_7d: -4.88198597,
          percent_change_30d: -0.92803481,
          percent_change_60d: 28.63321508,
          percent_change_90d: 23.08224524,
          market_cap: 191641522773.98227,
          market_cap_dominance: 18.6751,
          fully_diluted_market_cap: 191641522773.98,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 825,
      name: "Tether",
      symbol: "USDT",
      slug: "tether",
      num_market_pairs: 48715,
      date_added: "2015-02-25T00:00:00.000Z",
      tags: [
        "payments",
        "stablecoin",
        "asset-backed-stablecoin",
        "avalanche-ecosystem",
        "solana-ecosystem",
        "arbitrum-ecosytem",
        "moonriver-ecosystem",
        "injective-ecosystem",
        "bnb-chain",
        "usd-stablecoin",
        "optimism-ecosystem",
      ],
      max_supply: null,
      circulating_supply: 71116628508.94615,
      total_supply: 73141766321.23428,
      platform: {
        id: 1027,
        name: "Ethereum",
        symbol: "ETH",
        slug: "ethereum",
        token_address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      },
      cmc_rank: 3,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 1.0000984122329095,
          volume_24h: 37922439042.603035,
          volume_change_24h: 23.2743,
          percent_change_1h: -0.00400741,
          percent_change_24h: 0.00466091,
          percent_change_7d: -0.00604217,
          percent_change_30d: -0.00108395,
          percent_change_60d: 0.03114085,
          percent_change_90d: 0.0034667,
          market_cap: 71123627255.15471,
          market_cap_dominance: 6.9307,
          fully_diluted_market_cap: 73148964365.78,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 1839,
      name: "BNB",
      symbol: "BNB",
      slug: "bnb",
      num_market_pairs: 1206,
      date_added: "2017-07-25T00:00:00.000Z",
      tags: [
        "marketplace",
        "centralized-exchange",
        "payments",
        "smart-contracts",
        "alameda-research-portfolio",
        "multicoin-capital-portfolio",
        "bnb-chain",
      ],
      max_supply: null,
      circulating_supply: 157894631.0604023,
      total_supply: 159979963.59042934,
      platform: null,
      cmc_rank: 4,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 289.2489405996774,
          volume_24h: 469817969.3520803,
          volume_change_24h: 28.095,
          percent_change_1h: -0.23942373,
          percent_change_24h: -2.99430705,
          percent_change_7d: -6.75493997,
          percent_change_30d: -6.48926111,
          percent_change_60d: 17.02908536,
          percent_change_90d: -0.69151331,
          market_cap: 45670854760.59828,
          market_cap_dominance: 4.4504,
          fully_diluted_market_cap: 46274034985.71,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 3408,
      name: "USD Coin",
      symbol: "USDC",
      slug: "usd-coin",
      num_market_pairs: 10434,
      date_added: "2018-10-08T00:00:00.000Z",
      tags: [
        "medium-of-exchange",
        "stablecoin",
        "asset-backed-stablecoin",
        "fantom-ecosystem",
        "arbitrum-ecosytem",
        "moonriver-ecosystem",
        "bnb-chain",
        "usd-stablecoin",
        "optimism-ecosystem",
      ],
      max_supply: null,
      circulating_supply: 43138988230.94554,
      total_supply: 43138988230.94554,
      platform: {
        id: 1027,
        name: "Ethereum",
        symbol: "ETH",
        slug: "ethereum",
        token_address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      },
      cmc_rank: 5,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 0.9999440684223141,
          volume_24h: 4340500968.966324,
          volume_change_24h: 23.783,
          percent_change_1h: -0.01063897,
          percent_change_24h: 0.01065936,
          percent_change_7d: 0.00229703,
          percent_change_30d: 0.00410519,
          percent_change_60d: -0.00509767,
          percent_change_90d: -0.00839099,
          market_cap: 43136575399.27401,
          market_cap_dominance: 4.2036,
          fully_diluted_market_cap: 43136575399.27,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 52,
      name: "XRP",
      symbol: "XRP",
      slug: "xrp",
      num_market_pairs: 898,
      date_added: "2013-08-04T00:00:00.000Z",
      tags: [
        "medium-of-exchange",
        "enterprise-solutions",
        "arrington-xrp-capital-portfolio",
        "galaxy-digital-portfolio",
        "a16z-portfolio",
        "pantera-capital-portfolio",
      ],
      max_supply: 100000000000,
      circulating_supply: 50950912949,
      total_supply: 99989113908,
      platform: null,
      cmc_rank: 6,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 0.3680988158761521,
          volume_24h: 1131640018.7843883,
          volume_change_24h: 33.5069,
          percent_change_1h: 0.00640171,
          percent_change_24h: -2.59219126,
          percent_change_7d: -5.37099888,
          percent_change_30d: -8.72430047,
          percent_change_60d: 7.12384903,
          percent_change_90d: -6.72232564,
          market_cap: 18754970724.335804,
          market_cap_dominance: 1.8276,
          fully_diluted_market_cap: 36809881587.62,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 2010,
      name: "Cardano",
      symbol: "ADA",
      slug: "cardano",
      num_market_pairs: 661,
      date_added: "2017-10-01T00:00:00.000Z",
      tags: [
        "dpos",
        "pos",
        "platform",
        "research",
        "smart-contracts",
        "staking",
        "cardano-ecosystem",
        "cardano",
      ],
      max_supply: 45000000000,
      circulating_supply: 34670941194.39,
      total_supply: 35538184453.812,
      platform: null,
      cmc_rank: 7,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 0.33773434431242016,
          volume_24h: 382574227.31228155,
          volume_change_24h: 70.9911,
          percent_change_1h: -0.43010678,
          percent_change_24h: -3.72880245,
          percent_change_7d: -11.74336851,
          percent_change_30d: -11.60923266,
          percent_change_60d: 32.94954798,
          percent_change_90d: 4.72448978,
          market_cap: 11709567590.981785,
          market_cap_dominance: 1.141,
          fully_diluted_market_cap: 15198045494.06,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 3890,
      name: "Polygon",
      symbol: "MATIC",
      slug: "polygon",
      num_market_pairs: 598,
      date_added: "2019-04-28T00:00:00.000Z",
      tags: [
        "platform",
        "enterprise-solutions",
        "zero-knowledge-proofs",
        "scaling",
        "state-channel",
        "coinbase-ventures-portfolio",
        "binance-launchpad",
        "binance-labs-portfolio",
        "polygon-ecosystem",
        "moonriver-ecosystem",
        "injective-ecosystem",
      ],
      max_supply: 10000000000,
      circulating_supply: 8734317475.28493,
      total_supply: 10000000000,
      platform: null,
      cmc_rank: 8,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 1.1663124709143224,
          volume_24h: 567717502.6773337,
          volume_change_24h: 48.1118,
          percent_change_1h: -0.36951534,
          percent_change_24h: -3.15757719,
          percent_change_7d: -12.79825832,
          percent_change_30d: 6.81750665,
          percent_change_60d: 49.91489942,
          percent_change_90d: 26.03750835,
          market_cap: 10186943396.349712,
          market_cap_dominance: 0.9927,
          fully_diluted_market_cap: 11663124709.14,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 74,
      name: "Dogecoin",
      symbol: "DOGE",
      slug: "dogecoin",
      num_market_pairs: 650,
      date_added: "2013-12-15T00:00:00.000Z",
      tags: [
        "mineable",
        "pow",
        "scrypt",
        "medium-of-exchange",
        "memes",
        "payments",
        "doggone-doggerel",
        "bnb-chain",
      ],
      max_supply: null,
      circulating_supply: 132670764299.89409,
      total_supply: 132670764299.89409,
      platform: null,
      cmc_rank: 9,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 0.07585979772145365,
          volume_24h: 552866267.9535196,
          volume_change_24h: 142.2927,
          percent_change_1h: -0.23432771,
          percent_change_24h: -5.70680943,
          percent_change_7d: -9.87473951,
          percent_change_30d: -17.3257358,
          percent_change_60d: 5.2971847,
          percent_change_90d: -23.76991898,
          market_cap: 10064377343.34062,
          market_cap_dominance: 0.9807,
          fully_diluted_market_cap: 10064377343.34,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 4687,
      name: "Binance USD",
      symbol: "BUSD",
      slug: "binance-usd",
      num_market_pairs: 6338,
      date_added: "2019-09-20T00:00:00.000Z",
      tags: [
        "stablecoin",
        "asset-backed-stablecoin",
        "binance-chain",
        "harmony-ecosystem",
        "moonriver-ecosystem",
        "usd-stablecoin",
        "optimism-ecosystem",
      ],
      max_supply: null,
      circulating_supply: 9608316701.153376,
      total_supply: 9608316701.153376,
      platform: {
        id: 1839,
        name: "BNB",
        symbol: "BNB",
        slug: "bnb",
        token_address: "BUSD-BD1",
      },
      cmc_rank: 10,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 0.9999505210698205,
          volume_24h: 7862242811.330944,
          volume_change_24h: 36.583,
          percent_change_1h: -0.03218984,
          percent_change_24h: -0.04264844,
          percent_change_7d: -0.03113766,
          percent_change_30d: -0.01510954,
          percent_change_60d: -0.05562785,
          percent_change_90d: -0.01625593,
          market_cap: 9607841291.922176,
          market_cap_dominance: 0.9362,
          fully_diluted_market_cap: 9607841291.92,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 5426,
      name: "Solana",
      symbol: "SOL",
      slug: "solana",
      num_market_pairs: 437,
      date_added: "2020-04-10T00:00:00.000Z",
      tags: [
        "pos",
        "platform",
        "solana-ecosystem",
        "cms-holdings-portfolio",
        "kenetic-capital-portfolio",
        "alameda-research-portfolio",
        "multicoin-capital-portfolio",
        "okex-blockdream-ventures-portfolio",
      ],
      max_supply: null,
      circulating_supply: 378851088.66599125,
      total_supply: 539312704.8875339,
      platform: null,
      cmc_rank: 11,
      self_reported_circulating_supply: null,
      self_reported_market_cap: null,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 21.099679335774038,
          volume_24h: 485398950.3978387,
          volume_change_24h: 48.5479,
          percent_change_1h: -0.36054907,
          percent_change_24h: -3.65500552,
          percent_change_7d: -11.35530567,
          percent_change_30d: -10.27648552,
          percent_change_60d: 90.03066546,
          percent_change_90d: 56.15706113,
          market_cap: 7993636486.861314,
          market_cap_dominance: 0.779,
          fully_diluted_market_cap: 11379325134.84,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    {
      id: 6636,
      name: "Polkadot",
      symbol: "DOT",
      slug: "polkadot-new",
      num_market_pairs: 475,
      date_added: "2020-08-19T00:00:00.000Z",
      tags: [
        "substrate",
        "polkadot",
        "binance-chain",
        "polkadot-ecosystem",
        "three-arrows-capital-portfolio",
        "polychain-capital-portfolio",
        "arrington-xrp-capital-portfolio",
        "blockchain-capital-portfolio",
        "boostvc-portfolio",
        "cms-holdings-portfolio",
        "coinfund-portfolio",
        "fabric-ventures-portfolio",
        "fenbushi-capital-portfolio",
        "hashkey-capital-portfolio",
        "kenetic-capital-portfolio",
        "1confirmation-portfolio",
        "placeholder-ventures-portfolio",
        "pantera-capital-portfolio",
        "exnetwork-capital-portfolio",
        "web3",
        "spartan-group",
        "injective-ecosystem",
        "bnb-chain",
      ],
      max_supply: null,
      circulating_supply: 1162588442.1110451,
      total_supply: 1289012112.416225,
      platform: null,
      cmc_rank: 12,
      self_reported_circulating_supply: 904869778,
      self_reported_market_cap: 5467376019.728914,
      tvl_ratio: null,
      last_updated: "2023-03-03T12:56:00.000Z",
      quote: {
        USD: {
          price: 6.042168887343383,
          volume_24h: 303136073.9741895,
          volume_change_24h: 29.1782,
          percent_change_1h: -0.15131727,
          percent_change_24h: -4.42348871,
          percent_change_7d: -13.7193619,
          percent_change_30d: -1.97656626,
          percent_change_60d: 34.64177948,
          percent_change_90d: 8.15284479,
          market_cap: 7024555713.70837,
          market_cap_dominance: 0.6845,
          fully_diluted_market_cap: 7788428881.05,
          tvl: null,
          last_updated: "2023-03-03T12:56:00.000Z",
        },
      },
    },
    ,
  ]);
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  // useEffect(() => {
  //   fetch("https://appgrowthcompany.com:7081/api/v1/user/getMarketData", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => setData(responseJson.data.data));
  // }, []);

  return (
    <div className="conta_iner crpto_tdy ">
      <TableContainer
        sx={{
          padding: "40px 40px",
          boxSizing: "border-box",
          borderRadius: "20px",
          boxShadow: "0px 60px 50px #00000008",
          height: "540px",
        }}
        component={Paper}
      >
        <Table
          sx={{ minWidth: 650, height: "100px", overflowY: "auto " }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="tbl_cll" align="left">
                #
              </TableCell>
              <TableCell className="tbl_cll" align="left">
                Name
              </TableCell>
              <TableCell className="tbl_cll" align="center">
                Last Price
              </TableCell>
              <TableCell className="tbl_cll" align="center">
                24h Change
              </TableCell>
              <TableCell className="tbl_cll" align="center">
                Market Cap
              </TableCell>
              <TableCell className="tbl_cll" align="center">
                Last 7 Days
              </TableCell>
              <TableCell className="tbl_cll" align="center">
                Last Updated
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row" className="srl_no">
                  {item?.cmc_rank}
                </TableCell>
                <TableCell align="left" className="cntnt_nm">
                  {item?.name}
                  <span className="cntnt_shrtnm">({item?.symbol}) </span>
                </TableCell>
                <TableCell align="center" className="tbl_bld">
                  {USDollar.format(item?.quote?.USD?.price)}
                  {/* ${Number.parseFloat(item?.quote?.USD?.price).toFixed(2)} */}
                </TableCell>
                <TableCell
                  align="center"
                  className={
                    item?.quote?.USD?.percent_change_24h < 0
                      ? "ng_chng"
                      : "pstv_chng"
                  }
                >
                  {Number.parseFloat(
                    item?.quote?.USD?.percent_change_24h
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell align="center" className="tbl_bld">
                  {USDollar.format(item?.quote?.USD?.market_cap)}
                  {/* ${Number.parseFloat(item?.quote?.USD?.market_cap).toFixed()} */}
                </TableCell>
                <TableCell
                  align="center"
                  className={
                    item?.quote?.USD?.percent_change_7d < 0
                      ? "ng_chng"
                      : "pstv_chng"
                  }
                >
                  {Number.parseFloat(
                    item?.quote?.USD?.percent_change_7d
                  ).toFixed(2)}
                  %
                </TableCell>
                <TableCell align="center">
                  {dayjs(item?.quote?.USD?.last_updated).format("YYYY-MM-DD")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CryptoToday;
